// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// fonts
$font-family-marker: 'Permanent Marker', cursive;
$font-family-lato: 'Lato', sans-serif;
$font-family-nunito: 'Nunito', sans-serif;
$font-family-nunito-sans: 'Nunito Sans', sans-serif;
$font-family-poppins: 'Poppins', sans-serif;

// colores
$rosado: #FF347D;
$celeste: #41C0F6;
$amarillo: #FFCC18;
$azul: #0C2A49;
$negro: #1D1D1B;
$negro2: #302938;
$negro3: #343432;
$fondo: #9B9B9B;
$gris: #FAFAFA;

$custom-colors: (
        "rosado": $rosado,
        "celeste": $celeste,
        "amarillo": $amarillo,
        "azul": $azul,
        "negro": $negro,
        "negro2": $negro2,
        "negro3": $negro3,
        "fondo": $fondo,
        "gris": $gris
);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors: map-remove($theme-colors, "primary", "secondary", "light", "dark");

//Generating bg and text color for all base Colors
@each $color, $value in $custom-colors {
  .bg-#{$color} {
    background-color: $value;
  }

  .text-#{$color} {
    color: $value;
  }
}

// body
//$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size: 32px;
//$h3-font-size:                $font-size-base * 1.75 !default;

$body-color: $negro;
$font-family-sans-serif: $font-family-nunito-sans;

$font-size-base: 1rem;
$font-size-sm: $font-size-base;
$font-size-lg: $font-size-base * 1.25;

$link-color: $rosado;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: none;

// varios
$container-padding-x: 32px;
$border-radius: 48px;

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 4,
        7: $spacer * 5,
        8: $spacer * 6,
        9: $spacer * 7,
);

// botones
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-padding-y: 8px;
$btn-padding-x: 32px;
$btn-font-family: $font-family-nunito;
$btn-border-width: 4px;

@import "../../node_modules/bootstrap/scss/bootstrap";